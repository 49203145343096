export default function Spinner (props) {

return (
<div style={{height: (props.height  ? props.height : "50vh")}} className="d-flex justify-content-center align-items-center">
<div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
<div className="spinner-grow" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
</div>
);
}
