import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
//import {API} from 'aws-amplify';
//import Header from './WebHeader.js';
//import ServicesSection from './ServicesSection.js';
//import Footer from './WebFooter.js';
import Spinner from './Spinner.js';
import "../style2.scss";
//import "./styles.css";
//import "../../node_modules/bootstrap/js/dist/popover.js";
import "../../node_modules/bootstrap/js/dist/collapse.js";


//const Spinner = React.lazy(() => import('./Spinner.js'));
const Header = React.lazy(() => import('./WebHeader.js'));
const ServicesSection = React.lazy(() => import('./ServicesSection.js'));
const Footer = React.lazy(() => import('./WebFooter.js'));


const themeCardStyles={
  light:"1border 1border-1 1border-dark card shadow-lg",
  dark: "border border-0 border-light card shadow-lg bg-dark",
  orange: "border border-1 border-warning",
  darkOrange: "border border-1 border-warning",
  blue: "border border-1 border-primary",
  darkBlue: "border border-1 border-primary",
  cyborg: "border border-1 border-electricblue",
  quartz: "border border-0 border-hotpink shadow-lg",
  journal: "border border-0 border-lightorange card shadow-lg",
  lux: "border border-0 border-luxblack shadow",
  sketchy: "border border-0 border-sketchyblack 1card shadow-lg",
  slate: "shadow-lg 1border 1border-1 1border-slatetext card bg-slategray",
};


const fontCombos={
  light: {headingFont: "IBM Plex Sans Condensed", textFont: "IBM Plex Sans"},
  dark: {headingFont:"Oswald", textFont: "Source Sans Pro"},
  cyborg: {headingFont:"Barlow Condensed", textFont: "Montserrat"},
  quartz: {headingFont:"Karla", textFont: "Merriweather"},
  journal: {headingFont:"News Cycle", textFont: "News Cycle"},
  lux: {headingFont:"Nunito Sans", textFont: "Nunito Sans"},
  sketchy: {headingFont:"'Cabin Sketch', cursive", textFont: "Neucha"},
  slate: {headingFont:"Roboto", textFont: "Roboto"},
  default: {headingFont:"", textFont:""},
  arialGeorgia: {headingFont:"Arial", textFont:"Georgia"},
  oswaldQuattrocento: {headingFont: "Oswald", textFont:"Quattrocento"},
  loraRoboto: {headingFont: "Lora", textFont:"Roboto"},
  playfairDisplaySourceSansPro: {headingFont: "Playfair Display", textFont:"Source Sans Pro"},
};

  const pageWidthTypes={
    narrow: "col-md-6 offset-md-3",
    regular: "col-lg-8 col-md-12 offset-md-0 offset-lg-2",
    wide: "col-md-10 offset-md-1",
    fullWidth: "col-md-12 offset-md-0",
  };

  const headerThemeStyles={
    light: {iconClasses: "text-dark", btnClasses: "btn btn-dark", backgroundClass: "bg-light", navClasses: "navbar navbar-expand-lg navbar-dark bg-dark", divClasses: "bg-dark"},
    dark: {iconClasses: "",btnClasses: "btn btn-light", backgroundClass: "bg-dark text-white", navClasses: "navbar navbar-expand-lg navbar-light bg-light", divClasses: "bg-light"},
    orange: {iconClasses: "",navClasses: "navbar navbar-expand-lg navbar-light bg-warning", divClasses: "bg-warning"},
    darkOrange: {navClasses: "navbar navbar-expand-lg navbar-light bg-warning", divClasses: "bg-warning"},
    blue: {navClasses: "navbar navbar-expand-lg navbar-dark bg-primary", divClasses: "bg-primary"},
    darkBlue: {navClasses: "navbar navbar-expand-lg navbar-dark bg-primary", divClasses: "bg-primary"},
    cyborg: {iconClasses: "text-electricblue",btnClasses: "btn btn-electricblue", backgroundClass: "bg-jetblack text-white", navClasses: "navbar navbar-expand-lg navbar-dark bg-electricblue", divClasses: "bg-electricblue"},
    quartz: {iconClasses: "text-hotpink",btnClasses: "btn btn-hotpink text-white", backgroundClass: "bg-paleblue text-white", navClasses: "navbar navbar-expand-lg navbar-dark bg-hotpink", divClasses: "bg-hotpink"},  
    journal: {iconClasses: "text-lightorange",btnClasses: "btn btn-lightorange text-white", backgroundClass: "bg-white", navClasses: "navbar navbar-expand-lg navbar-dark bg-lightorange", divClasses: "bg-lightorange"}, 
    lux: {iconClasses: "text-luxblack",btnClasses: "btn btn-luxblack text-white", backgroundClass: "bg-white", navClasses: "navbar navbar-expand-lg navbar-dark bg-luxblack", divClasses: "bg-luxblack"},
    sketchy: {iconClasses: "text-sketchyblack",btnClasses: "btn btn-sketchyblack text-white", backgroundClass: "bg-white", navClasses: "navbar navbar-expand-lg navbar-dark bg-sketchyblack", divClasses: "bg-sketchyblack"},
    slate: {iconClasses: "text-slategray",btnClasses: "btn btn-slatetext text-slategray", backgroundClass: "bg-slategray text-slatetext", navClasses: "navbar navbar-expand-lg navbar-light bg-slatetext", divClasses: "bg-slatetext"}
};

export default function WebContainer(props) {

  const [content, setContent]=useState(null);
  const { Id } = useParams();
  const [isLoading, setIsLoading]=useState(true);
  const history = useHistory();
  const [themeCardStyle, setThemeCardStyle] = useState(null);
  const [fontCombo, setFontCombo] = useState(null);
  const [pageWidthClasses, setPageWidthClasses]=useState(null);
  const [headerThemeClasses, setHeaderThemeClasses]=useState(null);

async function getContent2(Id) {

  const res= await fetch("https://apniweb.site/dev/?Id="+Id);
  const body = await res.json();
  return body;
}
/*
async function getContent(Id) {
    const apiName = 'magicO';
    const path = '/';
    const myInit = {
      queryStringParameters: {
        Id: Id // OPTIONAL
      }
    };
    return await API.get(apiName, path, myInit);

}
*/

  useEffect( async () => {


    async function onLoad() {
      //setIsLoading(true);
      try {

    var content=await getContent2(Id); //loadListing();
    if (!content) history.push('/404');
    else {

      document.title = content.companyName;

      //var design=await loadDesign();
      var des=content.design;
      if (des && Object.keys(des).length === 0) {
        alert('Your site is not published yet, please publish the site from the edit page.'); 
	history.push('/');
      } else {
        //design=design[0].design;
        //setDesign(des);
	//console.log(content);
        setContent(content);
	
               var themeCardStyle=themeCardStyles[content.design.theme];
                setThemeCardStyle(themeCardStyle);

        var headerThemeClasses=headerThemeStyles[content.design.theme];
        setHeaderThemeClasses(headerThemeClasses);

                var fontCombo=fontCombos[content.design.theme];
                setFontCombo(fontCombo);

        var pageWidthClasses=pageWidthTypes[content.design.pageWidthType];
        setPageWidthClasses(pageWidthClasses);


      }
    }
      } catch (e) {
        //onError(e);
      }
      setIsLoading(false);
    }
    onLoad();
  }, [Id]);



  return (

  <>

  {isLoading ? <Spinner height="100vh"/> : 

    (<div className={headerThemeClasses && headerThemeClasses.backgroundClass} > 

      {content && content.components ? content.components.filter(c => {return c.visibility}).map((c,index) => (
        <div id={c.name} key={index} >{

                (c.name!=='header' && c.name!=='footer') ? content && content[c.name] ? <ServicesSection                   headerThemeClasses={headerThemeClasses}
 compIndex={index} themeCardStyle={themeCardStyle} Id={Id} fontCombo={fontCombo} theme={content.design.theme} component={c.name} content={content[c.name]} design={content.design[c.name]}/> : null
                : (c.name==='header') ? content ? <Header fontCombo={fontCombo} pageWidthClasses={pageWidthClasses} headerThemeClasses={headerThemeClasses} name={content.companyName} data={content} theme={content.design.theme}/> : null             
                : (c.name==='footer') ? <Footer fontCombo={fontCombo} pageWidthClasses={pageWidthClasses} data={content.companyName}/>
		: null
              }</div>
      )): ''}
  </div>)}

  </>

  );

}

//export default withRouter(WebContainer);
